import { Badge, BadgeKind, NavBar, TabBar, TabBarItem, TabBarItemKind, useNavProvider } from '@aposphaere/ui-components'
import React from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import PharmacyClusterSwitcher from '../components/PharmacyClusterSwitcher'
import QuarterSwitcher from '../components/QuarterSwitcher'
import SignOutButton from '../components/SignOutButton'
import { useTasksQuery } from '../hooks/graphql'
import logo from '../Icons/ApoLogoWhite.svg'
import { useCrmContext } from '../contexts/crmContext'
import { ADDRESSES_PAGE_ROUTE, REMINDERS_PAGE_ROUTE, START_PAGE_ROUTE, DASHBOARD_PAGE_ROUTE, REGIONAL_TOOL_LINK, LINKS_PAGE_ROUTE } from '../routes'

const MainLayout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const history = useHistory()
  const { data } = useTasksQuery()
  const { navExpanded } = useNavProvider()
  const reminders = data || []
  const isDashboard = location.pathname === DASHBOARD_PAGE_ROUTE
  const { isUserManager } = useCrmContext()
  return (
    <div className="flex z-50 flex-wrap font-body">
      <>
        <div className={navExpanded ? 'hidden' : 'w-full'}>
          <NavBar logoUrl={logo} logoUrlSmall={logo} logoAlt="CRM Logo">
            <TabBar>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === START_PAGE_ROUTE}
                onClick={() => history.push(START_PAGE_ROUTE)}
              >
                Planung
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === ADDRESSES_PAGE_ROUTE}
                onClick={() => history.push(ADDRESSES_PAGE_ROUTE)}
              >
                Adressen
              </TabBarItem>

              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === REMINDERS_PAGE_ROUTE}
                onClick={() => history.push(REMINDERS_PAGE_ROUTE)}
              >
                <span>
                  {'Wiedervorlagen'}
                  {reminders.length ? <Badge kind={BadgeKind.primary}>{reminders.length}</Badge> : null}
                </span>
              </TabBarItem>

              <TabBarItem kind={TabBarItemKind.primary} selected={isDashboard} onClick={() => history.push(DASHBOARD_PAGE_ROUTE)}>
                {'Dashboard'}
              </TabBarItem>

              {isUserManager && (
                <a
                  href={REGIONAL_TOOL_LINK}
                  target="_blank"
                  rel="noopener noreferrer" // recommended for security reasons when using target="_blank"
                  style={{ display: 'contents' }}
                >
                  <TabBarItem kind={TabBarItemKind.primary} selected={location.pathname === REGIONAL_TOOL_LINK}>
                    {'Regionalleitertool'}
                  </TabBarItem>
                </a>
              )}

              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === LINKS_PAGE_ROUTE}
                onClick={() => history.push(LINKS_PAGE_ROUTE)}
              >
                {'Weitere Links'}
              </TabBarItem>
            </TabBar>

            <div className="flex items-center">
              <div className="flex space-x-2 xl:space-x-4">
                <div className="w-48 lg:w-72 2lg:w-96">
                  <div className="w-full" />
                  {!isDashboard && <PharmacyClusterSwitcher />}
                </div>
                {!isDashboard && (
                  <div className="w-20 lg:w-36 2lg:w-52">
                    <QuarterSwitcher />
                  </div>
                )}
              </div>
              <div className="border-l border-white border-solid w-px h-10 ml-2 mr-2 xl:ml-8 xl:mr-8" />
              <div className="flex items-center text-white">
                <SignOutButton />
              </div>
            </div>
          </NavBar>
        </div>
      </>
      {children}
    </div>
  )
}

export default withRouter(MainLayout)
